import React, { Component, Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './scss/style.scss';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const loading = (
  <div className='pt-3 text-center'>
    <div className='sk-spinner sk-spinner-pulse'></div>
  </div>
);

// Containers
const DefaultLayout = React.lazy(() =>
  import('./layout/clinic_Layout/clinic_layout')
);

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const ForgotPassword = React.lazy(()=> import('./views/pages/forgot/ForgotPassword'))
const Setnewpassword = React.lazy(()=> import('./views/pages/newpassword/Setnewpassword'))
const Landing_Page = React.lazy(() =>
  import('./views/pages/landing_Page/landing_page')
);

//const selected = moment(isoDateStr).toDate()

class App extends Component {
  componentDidMount() {
    AOS.init();
    // dashboardPage();
    document.body.classList.add('periodpal-app');
    return () => {
      document.body.classList.remove('periodpal-app');
    };
  }
  render() {
    return (
      <>
        <Router>
          <Suspense fallback={loading}>
            <Routes>
              <Route
                exact
                path='/periodpal_cdm'
                name='Landing page'
                element={<Landing_Page />}
              />
              <Route
                exact
                path='/login'
                name='Login Page'
                element={<Login />}
              />
              <Route
                exact
                path='/register'
                name='Register Page'
                element={<Register />}
              />
              <Route exact path='/periodpal_cdm/forgot_password' name='Forgot Password' element={<ForgotPassword />} />
              <Route exact path='/periodpal_cdm/set_newpassword' name='' element={<Setnewpassword/>} />
              <Route exact path='/404' name='Page 404' element={<Page404 />} />
              <Route exact path='/500' name='Page 500' element={<Page500 />} />
              <Route exact path='/*' name='Home' element={<DefaultLayout />} />
            </Routes>
          </Suspense>
        </Router>
        <ToastContainer />
      </>
    );
  }
}

export default App;
